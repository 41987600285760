import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import '../App.scss';
import youtube from '../img/youtube.png';
import discord from '../img/discord.svg';
import twitter from '../img/twitter.svg';
import header_spaceship from '../img/header_spaceship.png';
import { getMerkleData } from '../utils/merkle_data';

const WalletCheck = () => {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [hasChecked, setHasChecked] = useState(false);
    const [isPreSaleListed, setPreSaleListed] = useState(false);
    const [isOGSaleListed, setOGSaleListed] = useState(false);
    const [isFreeSaleListed, setFreeSaleListed] = useState(false);
    const [freeSaleAmount, setFreeSaleAmount] = useState(0);

    useEffect(() => {
        document.body.classList.add('wallet-check-page');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkWallet = () => {
        if (currentAccount) {
            const merkleData = getMerkleData(currentAccount);
            setPreSaleListed(merkleData.preSale);
            setOGSaleListed(merkleData.ogSale);
            setFreeSaleListed(merkleData.freeSale);
            setFreeSaleAmount(merkleData.freeSaleAmount);
            setHasChecked(true);
        }
    };

    const getWalletInfo = () => {
        if (hasChecked) {
            return (
                <div className="wallet-info">
                    {isPreSaleListed ? (
                        <label className="green">You are in PeepList</label>
                    ) : (
                        <label className="red">
                            Oops! You are not in PeepList!
                        </label>
                    )}
                    {isOGSaleListed ? (
                        <label className="green">You are in OG</label>
                    ) : (
                        <label className="red">You are not in OG</label>
                    )}
                    {
                        isFreeSaleListed ? (
                            <label className="green">
                                You are in the free sale listed for{' '}
                                {freeSaleAmount} mints.
                            </label>
                        ) : null
                        // <label className="red">
                        //     You are not free sale listed
                        // </label>
                    }
                </div>
            );
        }
        return '';
    };

    const checkEligibilityBtn = () => {
        return (
            <button onClick={checkWallet} className="btn" type="button">
                Check
            </button>
        );
    };

    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                        maxWidth: 600,
                        marginTop: 26
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black'
                        }
                    }
                }}
            />
            <section id="nav" className="nav-container">
                <nav className="navbar navbar-default navbar-static-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1"
                                aria-expanded="false"
                            >
                                <span className="sr-only">
                                    Toggle navigation
                                </span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>

                        <div
                            className="collapse navbar-collapse"
                            id="bs-example-navbar-collapse-1"
                        >
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <a href="/mint">Mint</a>
                                </li>
                                <li>
                                    <a href="/walletcheck">Wallet Check</a>
                                </li>
                                <li>
                                    <a href="/#about">About</a>
                                </li>
                                <li>
                                    <a href="/#roadmap">Roadmap</a>
                                </li>
                                <li>
                                    <a href="/#peeps">Peeps</a>
                                </li>
                                <li>
                                    <a href="/#team">Team</a>
                                </li>
                                <li>
                                    <a href="/#community">Community</a>
                                </li>
                                <li>
                                    <a href="/#story">Story</a>
                                </li>
                                <li>
                                    <a href="/contact">Contacts</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>

            <div className="ufo-container">
                <img src={header_spaceship} className="ufo" alt="img" />
            </div>

            <section id="wallet" className="wallet-check-container">
                <div className="container">
                    <div className="inner">
                        <h1 className="title">Wallet Check</h1>
                        <p className="para">
                            Enter your wallet address below to see if you are on
                            our PeepList
                        </p>

                        <div className="form-container">
                            <form>
                                <input
                                    type="text"
                                    className="text-field"
                                    onChange={e =>
                                        setCurrentAccount(e.target.value)
                                    }
                                    maxLength="256"
                                    name="address"
                                    data-name="Address"
                                    placeholder="Enter Your Address"
                                    id="address"
                                    autoComplete="off"
                                />
                                {checkEligibilityBtn()}
                                {getWalletInfo()}
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="grid">
                        <div className="left"></div>
                        <div className="center">
                            <a href="/contacts" className="footer-link">
                                Contacts
                            </a>
                        </div>
                        <div className="right">
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={discord} className="" alt="discord" />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={twitter} className="" alt="twitter" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCAE1CAbYyZ-lbED8oBA9y0Q"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={youtube} className="" alt="youtube" />
                            </a>
                        </div>
                    </div>
                    <div className="reserved">
                        <p>© 2023. Galaxypeeps.com. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default WalletCheck;
