import youtube from '../img/youtube.png';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import '../App.scss';
import abi from '../contracts/abi';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import * as Constants from '../constants/config';
import header_spaceship from '../img/header_spaceship.png';
import discord from '../img/discord.svg';
import twitter from '../img/twitter.svg';
import {
    getRoot,
    getProof,
    getMerkleData,
    MintPass,
    getFreeAmount
} from '../utils/merkle_data';
import lock_icon from '../img/lock_icon.png';
import Header from './Header';
const contractAddress = Constants.CONTRACT_ADDRESS;
const networkChainId = Constants.NETWORK_ID;
const wrongChainErrorMessage =
    'You need to change network to Goerli Test Network';

const Mint = () => {
    document.body.classList.add('mint-page');

    const mintTitles = ['Presale Mint', 'OG Mint', 'Free Mint', 'Public Mint'];
    const [mintStatuses, setMintStatuses] = useState([
        false,
        false,
        false,
        false
    ]); // presale, og, free, public
    if (mintTitles.length !== mintStatuses.length) {
        console.error('Mint titles and statuses are not the same length');
        throw new Error('Could not load Mint');
    }
    const numMints = mintTitles.length;

    const [currentAccount, setCurrentAccount] = useState(null);

    const [quantities, setQuantities] = useState([1, 1, 1, 1]); // presale, og, free, public
    const [tokenPrices, setTokenPrices] = useState([0, 0, 0, 0]); // presale, og, free, public
    const [tokenPricesString, setTokenPricesString] = useState([
        '0',
        '0',
        '0',
        '0'
    ]); // presale, og, free, public
    const [totalCosts, setTotalCosts] = useState([0, 0, 0, 0]); // presale, og, free, public
    const [totalCostsString, setTotalCostsString] = useState([
        '0',
        '0',
        '0',
        '0'
    ]); // presale, og, free, public
    const [remainingMints, setRemainingMints] = useState([0, 0, 0, 0]); // presale, og, free, public

    const [totalSupply, setTotalSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [contractMerkleRoot, setContractMerkleRoot] = useState('');

    const merkleRoot = getRoot();

    const decrementCount = index => {
        const quantity = quantities[index];
        if (quantity > 1) {
            setQuantities(quantities.map((q, i) => (i === index ? q - 1 : q)));
            const newCost = tokenPrices[index].mul(
                ethers.BigNumber.from(quantity - 1)
            );
            setTotalCosts(
                totalCosts.map((c, i) => (i === index ? newCost : c))
            );
            setTotalCostsString(
                totalCostsString.map((c, i) =>
                    i === index ? ethers.utils.formatEther(newCost) : c
                )
            );
        }
    };

    const incrementCount = index => {
        const quantity = quantities[index];
        if (quantity < remainingMints[index]) {
            setQuantities(quantities.map((q, i) => (i === index ? q + 1 : q)));
            const newCost = tokenPrices[index].mul(
                ethers.BigNumber.from(quantity + 1)
            );
            setTotalCosts(
                totalCosts.map((c, i) => (i === index ? newCost : c))
            );
            setTotalCostsString(
                totalCostsString.map((c, i) =>
                    i === index ? ethers.utils.formatEther(newCost) : c
                )
            );
        }
    };

    const checkNetwork = async () => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();

        if (chainId !== networkChainId) {
            toast(
                t => (
                    <span>
                        {wrongChainErrorMessage}
                        <button
                            onClick={onClickConnect}
                            className="btn wallet-connect"
                        >
                            Change
                        </button>
                    </span>
                ),
                { id: 'change-net' }
            );
            setCurrentAccount(null);

            return false;
        }
        return true;
    };

    const checkWalletIsConnected = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Make sure you have Metamask installed!', {
                id: 'toast'
            });
            return;
        } else {
            toast.success("Wallet exists! We're ready to go!", { id: 'toast' });
        }

        if (!(await checkNetwork())) return [null, false];
        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log('Found an authorized account: ', account);
            setCurrentAccount(account);
            return [account, true];
        } else {
            setCurrentAccount(null);
            toast.error('No authorized account found');
            return [null, true];
        }
    };

    const onClickConnect = async () => {
        const { ethereum } = window;
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x' + networkChainId }]
            });
        } catch (switchError) {
            toast.error('Could not switch to Network ID: ' + networkChainId);
        }
    };

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Please install Metamask!', { id: 'toast' });
        }

        try {
            if (!(await checkNetwork())) return;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts'
            });
            setCurrentAccount(accounts[0]);
            window.location.reload(false);
        } catch (err) {
            console.log(err);
        }
    };

    const goto = async url => {
        window.open(url, '_blank');
    };

    const tryMintTokens = async saleIndex => {
        if (saleIndex >= numMints) {
            throw new Error('saleIndex >= numMints, panic!');
        }

        const { ethereum } = window;
        if (!ethereum) {
            toast.error('Please install Metamask to continue', {
                duration: 60000
            });
            return;
        }

        const provider = new ethers.providers.Web3Provider(ethereum);
        if (!provider) {
            toast.error('Please install Metamask to continue', {
                duration: 60000
            });
            return;
        }

        if (!(await checkNetwork())) return;
        if (contractMerkleRoot !== merkleRoot) {
            toast.error(
                'Merkle root mismatch. Please try refreshing, clearing your cache, or contacting the team.',
                { duration: 60000 }
            );
            console.log('Fetched contract merkle root: ' + contractMerkleRoot);
            console.log('Internal merkle root: ' + merkleRoot);
            return;
        }

        if (!currentAccount) {
            toast.error('Please connect your wallet to continue', {
                duration: 60000
            });
            return;
        }

        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);
        const totalCost = totalCosts[saleIndex];
        const quantity = quantities[saleIndex];

        let nftTxn;
        switch (saleIndex) {
            case 0:
                const preSaleProof = getProof(currentAccount, MintPass.PreSale);
                nftTxn = nftContract.mintPreSaleTokens(quantity, preSaleProof, {
                    value: totalCost
                });
                break;
            case 1:
                const ogSaleProof = getProof(currentAccount, MintPass.OgSale);
                nftTxn = nftContract.mintOgSaleTokens(quantity, ogSaleProof, {
                    value: totalCost
                });
                break;
            case 2:
                const freeSaleProof = getProof(
                    currentAccount,
                    MintPass.FreeSale
                );
                const maxFreeAmount = getFreeAmount(currentAccount);
                nftTxn = nftContract.mintFreeSaleTokens(
                    quantity,
                    freeSaleProof,
                    maxFreeAmount,
                    { value: totalCost }
                );
                break;
            case 3:
                nftTxn = nftContract.publicSaleMint(quantity, {
                    value: totalCost
                });
                break;
            default:
                throw new Error('saleIndex >= numMints, panic!');
        }
        nftTxn = await nftTxn;
        toast(
            t => (
                <span>
                    Minting in progress...
                    <button
                        onClick={() =>
                            goto(`${Constants.ETHERSCAN}/tx/${nftTxn.hash}`)
                        }
                        className="btn view-tx"
                    >
                        View Tx
                    </button>
                </span>
            ),
            { id: 'view-tx', duration: 30000 }
        );
        nftTxn = await nftTxn.wait();
        if (nftTxn.status === 1) {
            toast.success('NFT mint successful!', { duration: 15000 });
        } else {
            toast.error('NFT mint failed.', { duration: 15000 });
        }
        setTimeout(() => {
            window.location.reload(false);
        }, 2000);
    };

    const connectWalletButton = () => {
        return (
            <button onClick={connectWalletHandler} className="btn btn-mint">
                <span>Connect Wallet</span>
            </button>
        );
    };

    useEffect(() => {
        checkWalletIsConnected().then(([account, onCorrectNetwork]) =>
            getConfig(account, onCorrectNetwork)
        );
        document.cookie = `referral_key=hello;max-age=604800`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConfig = async (currentAccount, onCorrectNetwork) => {
        try {
            if (!onCorrectNetwork) return;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(
                contractAddress,
                abi,
                provider
            );
            let info;
            if (currentAccount) {
                info = await contract.getUserMintInfo(currentAccount);
                const merkleData = getMerkleData(currentAccount);
                const maxPreSaleMintAmount =
                    info.maxMintInfo.maxPreSaleMintAmount.toNumber();
                const maxOgSaleMintAmount =
                    info.maxMintInfo.maxOgSaleMintAmount.toNumber();
                const maxFreeSaleMintAmount = merkleData.freeSaleAmount;
                const maxPublicSaleMintAmount =
                    info.maxMintInfo.maxPublicSaleMintAmount.toNumber();

                const preSaleMinted = info.mintInfo.preSaleMinted.toNumber();
                const ogSaleMinted = info.mintInfo.ogSaleMinted.toNumber();
                const freeSaleMinted = info.mintInfo.freeSaleMinted.toNumber();
                const publicSaleMinted =
                    info.mintInfo.publicSaleMinted.toNumber();

                const preSaleRemaining = Math.max(
                    maxPreSaleMintAmount - preSaleMinted,
                    0
                );
                const ogSaleRemaining = Math.max(
                    maxOgSaleMintAmount - ogSaleMinted,
                    0
                );
                const freeSaleRemaining = Math.max(
                    maxFreeSaleMintAmount - freeSaleMinted,
                    0
                );
                const publicSaleRemaining = Math.max(
                    maxPublicSaleMintAmount - publicSaleMinted,
                    0
                );

                setRemainingMints([
                    preSaleRemaining,
                    ogSaleRemaining,
                    freeSaleRemaining,
                    publicSaleRemaining
                ]);
            } else {
                info = await contract.getMintInfo();
            }

            console.log(info);

            setTotalSupply(info._totalSupply.toString());
            setMaxSupply(info._maxSupply.toString());
            setContractMerkleRoot(info._merkleRoot);
            const tokenPrices = [
                info.priceInfo.preSalePrice,
                info.priceInfo.ogSalePrice,
                ethers.BigNumber.from('0'),
                info.priceInfo.publicSalePrice
            ];
            setTokenPrices(tokenPrices);
            setTokenPricesString([
                ethers.utils.formatEther(info.priceInfo.preSalePrice),
                ethers.utils.formatEther(info.priceInfo.ogSalePrice),
                '0',
                ethers.utils.formatEther(info.priceInfo.publicSalePrice)
            ]);
            setMintStatuses([
                info.saleInfo.preSaleActive,
                info.saleInfo.ogSaleActive,
                info.saleInfo.freeSaleActive,
                info.saleInfo.publicSaleActive
            ]);
            setTotalCosts(
                tokenPrices.map((p, i) =>
                    p.mul(ethers.BigNumber.from(quantities[i]))
                )
            );
            setTotalCostsString(
                tokenPrices.map((p, i) =>
                    ethers.utils.formatEther(
                        p.mul(ethers.BigNumber.from(quantities[i]))
                    )
                )
            );

            if (info._merkleRoot !== merkleRoot) {
                toast.error(
                    'Merkle root mismatch. Please try refreshing, clearing your cache, or contacting the team.',
                    { duration: 60000 }
                );
                console.log(
                    'Fetched contract merkle root: ' + info._merkleRoot
                );
                console.log('Internal merkle root: ' + merkleRoot);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                        maxWidth: 600,
                        marginTop: 26
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black'
                        }
                    }
                }}
            />

            <Header />

            <div className="ufo-container">
                <img src={header_spaceship} className="ufo" alt="img" />
            </div>

            <section id="contact" className="mint-container">
                <div className="container">
                    <div className="inner">
                        <div className="mint-box-container">
                            <div className="mint-box">
                                <div className="title-container">
                                    <h2>Mint</h2>
                                    <p>
                                        Mint a Peep and join the Galaxy Peeps
                                        community.
                                    </p>
                                </div>
                                <h3 className="count">
                                    Supply Minted: {totalSupply}/{maxSupply}
                                </h3>
                                {mintTitles.map((title, index) => {
                                    if (contractMerkleRoot !== merkleRoot)
                                        return null;
                                    if (!currentAccount) return null;
                                    if (!mintStatuses[index]) {
                                        return (
                                            <div key={index}>
                                                <div className="warning-container">
                                                    <img
                                                        src={lock_icon}
                                                        alt="img"
                                                    />
                                                    <p>
                                                        {title} has not begun
                                                        yet
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }
                                    const cost = tokenPricesString[index];
                                    const quantity = quantities[index];
                                    const mintsRemaining =
                                        remainingMints[index];
                                    const totalCost = totalCostsString[index];
                                    if (mintsRemaining <= 0) {
                                        return (
                                            <div key={index}>
                                                <div className="warning-container">
                                                    <img
                                                        src={lock_icon}
                                                        alt="img"
                                                    />
                                                    <p>
                                                        You have 0 mints
                                                        remaining for {title}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={index}>
                                            <div className="info-container">
                                                <div className="img-container">
                                                    <img
                                                        src={header_spaceship}
                                                        alt="img"
                                                    />
                                                </div>
                                                {title}
                                                <div className="inner-info-container">
                                                    <p>Price Per NFT</p>
                                                    <h3>
                                                        <span>{cost}</span> ETH
                                                        + gas
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="mint-counter">
                                                <div className="counter">
                                                    <button
                                                        className="btn minus"
                                                        onClick={() =>
                                                            decrementCount(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        -
                                                    </button>
                                                    {quantity}/{mintsRemaining}
                                                    <button
                                                        className="btn plus"
                                                        onClick={() =>
                                                            incrementCount(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="total-container">
                                                <p>Total</p>
                                                <h3>
                                                    <span>{totalCost}</span> ETH
                                                    + gas
                                                </h3>
                                            </div>
                                            <button
                                                className="btn btn-mint"
                                                onClick={() =>
                                                    tryMintTokens(index)
                                                }
                                            >
                                                <span>
                                                    Mint {title.split(' ')[0]}
                                                </span>
                                            </button>
                                            <br />
                                        </div>
                                    );
                                })}

                                <p>
                                    <a href={Constants.OPENSEA_LINK}>Buy now</a>{' '}
                                    on OpenSea, don't miss out!
                                </p>
                                {currentAccount ? '' : connectWalletButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="grid">
                        <div className="left"></div>
                        <div className="center">
                            <a href="/contacts" className="footer-link">
                                Contacts
                            </a>
                        </div>
                        <div className="right">
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={discord} className="" alt="logo" />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={twitter} className="" alt="logo" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCAE1CAbYyZ-lbED8oBA9y0Q"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={youtube} className="" alt="youtube" />
                            </a>
                        </div>
                    </div>
                    <div className="reserved">
                        <p>© 2023. Galaxypeeps.com. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default Mint;
